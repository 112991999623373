function isJsonObject(obj) {
    return !!(obj && typeof obj === 'object' && !(obj instanceof Array));
}

function isNonEmptyString(str) {
    return !!(str && typeof str === 'string' && str.trim());
}

function isNonEmptyArray(arr) {
    return !!(arr && arr instanceof Array && arr.length);
}

function isNonEmptyObject(obj) {
    return !!(isJsonObject(obj) && Object.keys(obj).length);
}

function isArrayOfObjects(arr) {
    return isNonEmptyArray(arr) && isJsonObject(arr[0]);
}

/**
 * @param {object} obj
 * @param {string} key
 * @returns {boolean} boolean
 * @description
 * - checks that **obj** isNonEmptyObject
 * - and **obj** has **key**
 */
function containsKey(obj, key) {
    return isNonEmptyObject(obj) && obj.hasOwnProperty(key);
}

/**
 * @param {object} obj
 * @param {string} key
 * @returns {boolean} boolean
 * @description
 * - checks that **obj** isNonEmptyObject
 * - and **obj** has **key**
 * - and obj[key] isNonEmptyString
 */
function containsNonEmptyString(obj, key) {
    return containsKey(obj, key) && isNonEmptyString(obj[key]);
}

module.exports = {
    isJsonObject,
    isNonEmptyArray,
    isNonEmptyObject,
    isArrayOfObjects,
    isNonEmptyString,
    containsKey,
    containsNonEmptyString,
};

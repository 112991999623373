const { isNonEmptyString } = require('./helper.util');

/**
 * @description stores instances of dynamic iframe in a JSON object with iframe id as key
 */
let iframeHolder = new Object();

/**
 * @param {string} id iframe's unique ID (id attr)
 * @param {string} src iframe's src url
 * @param {string} name **Optional** iframe's name attr
 * @returns iframe instance
 */
function createIFrame(id, src, name = '') {
    if (!(isNonEmptyString(id) && isNonEmptyString(src))) return null;

    const iframe = document.createElement('iframe');

    iframe.id = id;
    iframe.src = src;
    if (isNonEmptyString(name)) iframe.name = name;

    iframe.style.position = 'fixed';
    iframe.style.display = 'none';
    iframe.style.zIndex = '-1000';
    iframe.style.left = '110vw';
    iframe.style.top = '110vh';
    iframe.style.height = '0px';
    iframe.style.width = '0px';
    iframe.style.opacity = '0';

    iframeHolder[id] = iframe;
    return iframe;
}

/**
 * @param {string} id iframe's unique ID (id attr)
 * @returns {HTMLIFrameElement | null} iframe instance | null
 */
function getIFrame(id) {
    if (iframeHolder.hasOwnProperty(id)) return iframeHolder[id];
    else return null;
}

/**
 * @param {string} id iframe's unique ID (id attr)
 * @param {string} src iframe's src url
 * @param {string} name **Optional** iframe's name attr
 * @description creates hidden iframe instance and appends that to body
 */
function initIFrame(id, src, name = '') {
    const iframe = createIFrame(id, src, name);
    const body = document.querySelector('body');
    if (iframe && body) body.appendChild(iframe);
}

/**
 * @param {string} id iframe's unique ID (id attr)
 * @description detaches iframe instance from body and removes it from storage
 */
function removeIFrame(id) {
    if (iframeHolder.hasOwnProperty(id) && iframeHolder[id]) {
        iframeHolder[id].remove();
        delete iframeHolder[id];
    }
}

/**
 * @description detaches all iframe instances and clears storage
 */
function destroy() {
    for (const iframeId in iframeHolder) removeIFrame(iframeId);
}

const iframeUtil = Object.freeze({
    iframeHolder,
    getIFrame,
    initIFrame,
    removeIFrame,
    destroy,
});

module.exports = iframeUtil;

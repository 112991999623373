const { containsNonEmptyString, containsKey, isNonEmptyString, isNonEmptyObject, isNonEmptyArray } = require('./helper.util');

let InMemoryStorage = {};
let riskMessageListener = null;

const SEPARATOR = '__';
const ERROR_MESSAGE_LIMIT = 2000;
const PLUGIN_PREFIX = 'RISK_PLUGIN';

const ErrorType = Object.seal({
    INTERNAL: 'riskPluginInternalError',
    EXTERNAL: 'riskPluginExternalError',
});

const StorageManager = Object.seal({
    dataKeys: [],
    setData: (key, value) => {
        InMemoryStorage[key] = value;
    },
    getData: (key) => InMemoryStorage[key],
    removeData: (key) => {
        delete InMemoryStorage[key];
    },
});

/**
 * Generates a storage key containing plugin-prefix, providerCode, and providerType
 * @param {string} providerType risk provider type
 * @param {string} providerCode risk provider code
 * @returns {string} storage key
 */
function generateKey(providerType, providerCode) {
    return [PLUGIN_PREFIX, providerType, providerCode].join(SEPARATOR);
}

/**
 * Reads providerType and providerCode from storage key
 * @param {string} dataKey storage key
 * @returns {[string, string]} [providerType, providerCode]
 */
function parseKey(dataKey) {
    let providerType = '';
    let providerCode = '';
    if (isNonEmptyString(dataKey)) [, providerType, providerCode] = dataKey.split(SEPARATOR);

    return [providerType, providerCode];
}

/**
 * Stores the provider error into external storage
 * @param {string} providerType risk provider type
 * @param {string} providerCode risk provider code
 * @param {string} providerError string containing risk provider's error message
 * @param {string} errorType ErrorType.INTERNAL (default) | ErrorType.EXTERNAL
 */
function storeProviderError(providerType, providerCode, providerError, errorType = ErrorType.INTERNAL) {
    if (!(isNonEmptyString(providerType) && isNonEmptyString(providerCode) && isNonEmptyString(providerError))) return;
    if (!isValidErrorType(errorType)) errorType = ErrorType.INTERNAL;
    if (providerError.length > ERROR_MESSAGE_LIMIT) providerError = providerError.slice(0, ERROR_MESSAGE_LIMIT);

    const key = generateKey(providerType, providerCode);
    if (!StorageManager.dataKeys.includes(key)) StorageManager.dataKeys.push(key);

    let data = {};
    data[errorType] = providerError;

    StorageManager.setData(key, data);
}

/**
 * Stores the provider results into external storage
 * @param {string} providerType risk provider type
 * @param {string} providerCode risk provider code
 * @param {object} providerResults JSON object containing risk provider's results
 */
function storeProviderResults(providerType, providerCode, providerResults) {
    if (!(isNonEmptyString(providerType) && isNonEmptyString(providerCode) && isNonEmptyObject(providerResults))) return;

    const key = generateKey(providerType, providerCode);
    if (!StorageManager.dataKeys.includes(key)) StorageManager.dataKeys.push(key);

    StorageManager.setData(key, providerResults);
}

/**
 * Returns array of parameters object in OPG requested format
 * @param {object} providerResults JSON
 * @returns {object[]} [{name: 'string', value: 'stringified value'}, ...]
 */
function getParameters(providerResults) {
    if (!isNonEmptyObject(providerResults)) return [];
    let parameters = [];

    for (const key in providerResults) {
        if (!isNonEmptyString(key)) continue;
        let value = providerResults[key];

        if (value === null || value === undefined) continue;
        if (typeof value !== 'string') value = JSON.stringify(value);
        value = value.trim();
        if (!value) continue;

        parameters.push({
            name: key,
            value: value,
        });
    }

    return parameters;
}

/**
 * If storage key exists in external storage, returns stored data in providerRequest format
 * @param {string} dataKey storage key
 * @returns providerRequest object if data exists in external storage otherwise null
 */
function getProviderResults(dataKey) {
    if (!(isNonEmptyString(dataKey) && StorageManager.dataKeys.includes(dataKey))) return null;

    const [providerType, providerCode] = parseKey(dataKey);
    if (!(providerType && providerCode)) return null;
    const providerResults = StorageManager.getData(dataKey);
    const parameters = getParameters(providerResults);
    if (!isNonEmptyArray(parameters)) return null;

    return {
        providerType,
        providerCode,
        parameters,
    };
}

/**
 * Validated risk plugin error type
 * @param {string} type ErrorType
 * @returns {boolean}
 */
function isValidErrorType(type) {
    return type === ErrorType.INTERNAL || type === ErrorType.EXTERNAL;
}

/**
 * Validates message object according to risk provider's error message format
 * @param {object} message iframe message from risk provider iframe
 * @returns boolean
 */
function isValidRiskErrorMessage(message) {
    return (
        containsNonEmptyString(message, 'messageType') &&
        message.messageType === 'RISK_HANDLER_ERROR' &&
        containsKey(message, 'data') &&
        containsNonEmptyString(message.data, 'providerType') &&
        containsNonEmptyString(message.data, 'providerCode') &&
        containsKey(message.data, 'providerError') &&
        isNonEmptyString(message.data.providerError)
    );
}

/**
 * Validates message object according to risk provider's message format
 * @param {object} message iframe message from risk provider iframe
 * @returns boolean
 */
function isValidRiskMessage(message) {
    return (
        containsNonEmptyString(message, 'messageType') &&
        message.messageType === 'RISK_HANDLER' &&
        containsKey(message, 'data') &&
        containsNonEmptyString(message.data, 'providerType') &&
        containsNonEmptyString(message.data, 'providerCode') &&
        containsKey(message.data, 'providerResults') &&
        isNonEmptyObject(message.data.providerResults)
    );
}

/**
 * Adds a listener for messages from risk provider iframes
 * @param {string} origin iframe origin
 */
function addMessageListener(origin) {
    if (riskMessageListener || !isNonEmptyString(origin)) return;

    riskMessageListener = function (event) {
        if (event.origin !== origin) return;
        const message = event.data || null;
        if (isValidRiskMessage(message))
            storeProviderResults(message.data.providerType, message.data.providerCode, message.data.providerResults);
        else if (isValidRiskErrorMessage(message))
            storeProviderError(message.data.providerType, message.data.providerCode, message.data.providerError, ErrorType.EXTERNAL);
    };

    window.addEventListener('message', riskMessageListener);
}

/**
 * Removes risk provider iframe messages listener
 */
function removeMessageListener() {
    if (!riskMessageListener) return;
    window.removeEventListener('message', riskMessageListener);
    riskMessageListener = null;
}

/**
 * Returns list of providerRequest objects
 * @returns {object[]} [providerRequest]
 */
function getRiskData() {
    let riskData = [];
    StorageManager.dataKeys.forEach((key) => {
        const providerData = getProviderResults(key);
        if (providerData) riskData.push(providerData);
    });
    return riskData;
}

/**
 * Removed risk provider's data from external storage
 */
function clear() {
    StorageManager.dataKeys.forEach((key) => {
        StorageManager.removeData(key);
    });
    StorageManager.dataKeys = [];
    removeMessageListener();
}

/**
 * Configures External Storage
 * @param {function} setData external storage's method to store data
 * - storeData(key: string, data: any): void
 * @param {function} getData external storage's method to get stored data
 * - getStoredData(key: string): any
 * @param {function} removeData external storage's method to remove stored data
 * - removeStoredData(key: string): void
 */
function configureExternalStorage(setData, getData, removeData) {
    if (typeof setData === 'function') StorageManager.setData = setData;
    if (typeof getData === 'function') StorageManager.getData = getData;
    if (typeof removeData === 'function') StorageManager.removeData = removeData;
}

/**
 * Initializes data utility
 * @param {string} origin risk providers iframe origin
 * @description Listens for risk provider's iframe events from origin
 */
function init(origin) {
    if (isNonEmptyString(origin)) addMessageListener(origin);
}

const dataUtil = Object.freeze({
    init,
    clear,
    configureExternalStorage,
    // following are exported for testing purposes
    SEPARATOR,
    ErrorType,
    PLUGIN_PREFIX,
    ERROR_MESSAGE_LIMIT,
    StorageManager,
    generateKey,
    parseKey,
    getParameters,
    isValidErrorType,
    storeProviderError,
    storeProviderResults,
    isValidRiskErrorMessage,
    getProviderResults,
    isValidRiskMessage,
    getRiskData,
});

module.exports = dataUtil;

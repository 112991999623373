const dataUtil = require('./utils/data.util');
const iframeUtil = require('./utils/iframe.util');
const InternalError = require('./utils/error.util');
const { isArrayOfObjects, containsKey, containsNonEmptyString, isNonEmptyString } = require('./utils/helper.util');

/**
 * Validates providerConfig
 * @param {object} config provider config from lists request
 * @returns boolean
 */
function isValidProviderConfig(config) {
    return (
        containsNonEmptyString(config, 'providerCode') &&
        containsNonEmptyString(config, 'providerType') &&
        containsKey(config, 'links') &&
        containsNonEmptyString(config.links, 'iframe')
    );
}

/**
 * @param {object} config (risk provider config)
 * @returns {string} riskProviderUrl with queryParams
 */
function getProviderUrl(config, currentOrigin = '') {
    if (!isValidProviderConfig(config)) return '';
    const baseUrl = config.links.iframe;
    let queryParams = '?providerType=' + config.providerType + '&providerCode=' + config.providerCode + '&targetOrigin=' + currentOrigin;

    if (containsKey(config, 'parameters') && isArrayOfObjects(config.parameters))
        config.parameters.forEach((param) => {
            if (containsNonEmptyString(param, 'name') && containsKey(param, 'value')) queryParams += '&' + param.name + '=' + param.value;
        });

    return baseUrl + queryParams;
}

/**
 * Extracts and returns the origin from url
 * @param {string} url
 * @returns URL's origin
 */
function getOrigin(url) {
    let origin = '';
    if (isNonEmptyString(url) && url.startsWith('http')) {
        const parts = url.split('/');
        if (parts.length >= 3) origin = parts[0] + '//' + parts[2];
    }
    return origin;
}

/**
 * Returns the OPG origin from lists request's response data
 * @param {object} listHandlerData JSON object
 * @returns OPG origin e.g. https://resources.integration.oscato.com
 * @description (Assuming that)
 * - All risk providers will share the same origin i.e. _OPG origin_
 * - Risk provider's iframe url is absolute and secure _(starts with https://)_
 */
function getRiskProvidersOrigin(riskProviderConfig) {
    if (!isValidProviderConfig(riskProviderConfig)) return '';
    return getOrigin(riskProviderConfig.links.iframe);
}

/**
 * @param {Array<object>} riskProviderConfigs Array of riskProviderConfigs (from LISTS response)
 * @description Initializes risk providers with the required configurations
 */
function init(riskProviderConfigs) {
    if (!(isArrayOfObjects(riskProviderConfigs) && isValidProviderConfig(riskProviderConfigs[0]))) return;

    /**
     * ### Assuming that
     * - All risk providers will share the same origin i.e. _OPG origin_
     * - Extracting origin from the first risk provider config _(iframe URL)_
     * - And it will be the same for rest of them
     * - Risk provider's iframe url is absolute and secure _(starts with https://)_
     */
    const origin = getRiskProvidersOrigin(riskProviderConfigs[0]);
    if (!origin)
        return dataUtil.storeProviderError(
            riskProviderConfigs[0].providerType,
            riskProviderConfigs[0].providerCode,
            InternalError.INVALID_ORIGIN
        );

    // removes existing iframe and cleans memory
    destroy();

    dataUtil.init(origin);

    riskProviderConfigs.forEach((providerConfig) => {
        const providerUrl = getProviderUrl(providerConfig, window.location.origin);
        if (providerUrl && containsNonEmptyString(providerConfig, 'providerCode') && containsNonEmptyString(providerConfig, 'providerType'))
            iframeUtil.initIFrame(
                providerConfig.providerCode + ':' + providerConfig.providerType, // id
                providerUrl, // src
                providerConfig.providerCode // name
            );
        else dataUtil.storeProviderError(providerConfig.providerType, providerConfig.providerCode, InternalError.INVALID_IFRAME_URL);
    });
}

/**
 * @description
 * - Detaches all instance of risk provider iframes
 * - Cleans memory from risk provider metadata
 */
function destroy() {
    iframeUtil.destroy();
    dataUtil.clear();
}

const RiskPlugin = Object.freeze({
    init,
    destroy,
    getRiskData: dataUtil.getRiskData,
    configureExternalStorage: dataUtil.configureExternalStorage,
});

//  Exported for testing purposes
module.exports = {
    dataUtil,
    iframeUtil,
    RiskPlugin,
    getOrigin,
    getProviderUrl,
    isValidProviderConfig,
    getRiskProvidersOrigin,
};
